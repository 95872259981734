.order_complete_wrap {
  margin: 30px 0;
  padding: 30px;
}
.order_complete_wrap h4 {
  font-size: 18px;
  color: #0a0a0a;
  font-weight: 500;
}
.order_complete_wrap ul {
  padding-left: 0;
  list-style: none;
}
.order_complete_wrap ul li span {
  font-weight: 700;
}
.order_complete_tick svg {
  font-size: 90px;
  margin-bottom: 20px;
  color: #d8294f;
}
.order_complete_button.btn.btn-primary {
  display: inline-block;
  padding: 5px 20px;
  background-color: #e3a129;
  font-size: 16px;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  width: fit-content;
  margin-bottom: 20px;
  border: none;
}
.order_complete_button.btn.btn-primary:hover {
  background-color: #d8294f;
  color: #fff;
}
.order_complete_button.btn.btn-primary:focus {
  box-shadow: none;
}
