.footer {
  padding: 50px 0;
  background-color: #6b071d;
}
.footer_menu_wrap {
  padding-left: 0;
  list-style: none;
}
.footer_menu_wrap li a {
  text-decoration: none;
  color: #fff;
  line-height: 26px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.footer_menu_wrap li a:hover {
  color: #e3a129;
}
.footer h4 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
  line-height: 24px;
  position: relative;
  margin-top: 10px;
  text-transform: uppercase;
  color: #e3a129;
}
.footer_logo {
  width: 50%;
  margin: 0 auto;
}
.footer_logo img {
  width: 100%;
}
.footer_social {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.footer_social li {
  padding: 0 10px;
}
.footer_social li svg {
  color: #fff;
  font-size: 25px;
}
.footer_social li svg:hover {
  color: #e3a129;
}
.footer_contact_det_wrap {
  padding-left: 0;
  list-style: none;
}
.footer_contact_det_wrap_item {
  margin: 10px 0;
  color: #d4d1d1;
  /* text-align: left; */
}
.footer_contact_det_wrap_item span {
  color: #fff;
  font-weight: 600;
  padding-right: 10px;
}
.footer_contact_det_wrap_item a {
  display: inline-block;
  color: #d4d1d1;
}
.footer p {
  color: #fff;
}
