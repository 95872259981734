.about_wrapper {
  padding: 0 0 100px 0;
}

.about_feature_wrap {
  padding-bottom: 100px;
}
.about_features {
  text-align: left;
  width: 70%;
  margin: 0 auto;
}
.about_features_icon svg {
  font-size: 70px;
  color: #e3a129;
}
.about_features h5 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: #222;
  margin: 12px 0;
}
.about_features p {
  color: #888;
}
.our_vis_mis svg {
  font-size: 80px;
  color: #e3a129;
  margin-bottom: 10px;
}
.about_our_vis_mis_wrap {
  padding-bottom: 100px;
}
.about_content h2 {
  /* font-size: 18px; */
  font-weight: 500;
  color: #0a0a0a;
  margin-bottom: 25px;
  padding-bottom: 10px;
  position: relative;
  text-align: left;
}
.about_content h2::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 70px;
  border-bottom: 4px solid #e3a129;
}
.about_content p {
  text-align: justify;
}
.our_vis_mis {
  width: 90%;
  margin: 0 auto;
  box-shadow: 0px 0px 30px -1px rgb(181 181 181 / 12%);
  border-radius: 10px;
  padding: 30px 30px;
}
.our_vis_mis p {
  color: #888;
}
.about_img img {
  width: 100%;
  border-radius: 10px;
}
.about_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .about_img {
    margin-bottom: 20px;
  }
  .our_vis_mis {
    margin-bottom: 20px;
  }
  .about_our_vis_mis_wrap {
    padding-bottom: 50px;
  }
  .about_wrapper {
    padding: 50px 0;
  }

  .about_feature_wrap {
    padding-bottom: 50px;
  }
}
