.cart_head {
  margin: 30px 0;
}
.cart_table {
  border: 0.5px solid #dad8d8;
  border-radius: 5px;
  margin: 50px 0;
}

.cart_wrap {
  border: 0.5px solid #dad8d8;
  border-radius: 5px;
  margin: 50px 0;
}
.cart_head_title {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  color: #0a0a0a;
  margin-bottom: 25px;
  padding-bottom: 10px;
  position: relative;
}
.cart_head_title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: 2px solid #e3a129;
  width: 30px;
}
.cart_btn.btn.btn-primary {
  display: inline-block;
  padding: 5px 20px;
  background-color: #e3a129;
  font-size: 16px;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  width: fit-content;
  margin-bottom: 20px;
  border: none;
}
.cart_btn.btn.btn-primary:hover {
  background-color: #d8294f;
  color: #fff;
}
.cart_btn.btn.btn-primary:focus {
  box-shadow: none;
}

.cart_list_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px 10px 30px;
}
.cart_list_head h5 {
  margin-bottom: 0;
}
.cart_table_list {
  display: flex;

  padding: 20px 30px 20px 30px;
  border-bottom: 0.5px solid #dad8d8;
}
.cart_table_list_img_wrap {
  width: 100px;
  height: 100px;
}
.cart_table_list_img_wrap img {
  width: 100%;
}
.cart_table_list_info {
  flex-grow: 1;
  text-align: left;
  padding-left: 20px;
  overflow: hidden;
}
.cart_table_list_info h4 {
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  padding: 0 0 12px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cart_table_list_info_price {
  font-size: 18px;
  color: #000;
  line-height: 22px;
}
.cart_table_list_info_price span {
  padding: 0 12px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: line-through;
}
.cart_table_list_info_qty {
  font-weight: 600;
  display: flex;
  justify-content: end;
}
.cart_table_list_info_qty button.btn.btn-outline-secondary {
  border-radius: 50%;
  background-color: #e3a129;
  color: #6b071d;
  border-color: #e3a129;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin: 0 10px;
  padding: 0;
}
.cart_table_list_info_qty button.btn.btn-outline-secondary:focus {
  box-shadow: none;
}
.cart_mrp_total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #dad8d8;
  padding: 10px 20px;
}
.cart_total_amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.cart_mrp_total_label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}
.cart_mrp_total_price {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.cart_total_amount_label,
.cart_total_amount_price {
  font-size: 14px;
  color: #000;
  font-weight: 700;
  margin-bottom: 20px;
}
