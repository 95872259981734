.nav-header {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 9;
}
.header-cart-desk {
  display: none;
}
.header-cart-resp {
  display: block;
}
.header-cart.btn.btn-primary {
  background-color: #e3a129;
  border-color: #e3a129;
  color: #000;
}
.header-cart.btn.btn-primary:focus {
  box-shadow: none;
}
.header-cart svg {
  color: #6b071d;
  font-size: 25px;
}
.title-bar-child {
  display: flex;
  justify-content: space-between;
}
.title-bar-child ul {
  display: flex;
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
  align-items: center;
}
.title-bar-child ul li {
  padding-right: 10px;
}
.title-bar-child ul li svg {
  color: #fff;
  font-size: 20px;
}
.title-bar-child ul li svg:hover {
  color: #6b071d;
}
.header_logo {
  width: 200px;
}
.header_logo img {
  width: 100%;
}
.navbar {
  background-color: #6b071d;
}
.navbar-light .navbar-nav .nav-link {
  color: #e3a129;
}
.title-bar {
  padding: 10px 0;
  background: #ab8e66;
}
.title-bar .form-select {
  color: #fff;
  background-color: #ab8e66;
  border-color: #ab8e66;
  cursor: pointer;
  /* background-image: url("../../assets/img/oatharrow1.svg"); */
}
.title-bar .form-select:focus {
  box-shadow: none;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(107, 7, 29)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-light .navbar-toggler {
  background-color: #e3a129;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}
.header-cart-resp,
.header-cart-desk {
  position: relative;
}
.header-cart-resp span,
.header-cart-desk span {
  position: absolute;
  top: 3px;
  right: 6px;
  font-weight: 700;
  font-size: 9px;
  background: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-light .navbar-nav .nav-link:focus {
  color: #fff;
}
#dropdown-basic.btn-success {
  color: #e3a129;
  background-color: #6b071d;
  border-color: #6b071d;
}
#dropdown-basic.btn-success:focus {
  box-shadow: none;
}
.dropdown-menu {
  background-color: #6b071d;
  border: 1px solid rgb(107 7 29);
  text-align: center;
  min-width: max-content;
}
.dropdown-menu a {
  color: #e3a129;
  display: block;
  text-decoration: none;
  text-align: left;
  padding: 3px 12px;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .header-cart-desk {
    display: block;
    right: -24%;
  }
  .header-cart-resp {
    display: none;
  }
  .dropdown-menu a {
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  .header-cart-desk {
    display: block;
    right: unset;
  }
  .header-cart-resp {
    display: none;
  }
}
