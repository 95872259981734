.product_inner_head {
  margin: 30px 0;
}
.product_inner_wrapper {
  margin-bottom: 30px;
}
.product_inner_img {
  width: 550px;
  height: 550px;
  object-fit: cover;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  /* margin: 0 auto; */
}
.product_inner_img:hover img {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}
.product_inner_img img {
  width: 100%;
}
.product_inner_info {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.product_inner_info h2 {
  font-size: 26px;
  font-weight: 500;
  color: #333;
}
.product_inner_rating {
  color: #ffb933;
  margin: 10px 0;
}
.product_inner_description {
  margin: 30px 0;
  color: #767070;
}
.product_inner_price {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.product_inner_original_price {
  color: #aaa;
  margin-right: 15px;
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 500;
  text-decoration: line-through;
}
.product_inner_offer_price {
  font-size: 22px;
  color: #000;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 0;
}
.product_inner_add_to_cart_btn.btn.btn-primary {
  display: inline-block;
  padding: 10px 30px;
  background-color: #e3a129;
  font-size: 16px;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  width: fit-content;
  margin-bottom: 20px;
  cursor: pointer;
  border: none;
}
.product_inner_add_to_cart_btn.btn.btn-primary:hover {
  background-color: #d8294f;
  color: #fff;
}
.product_inner_add_to_cart_btn.btn.btn-primary:focus {
  box-shadow: none;
}
@media only screen and (max-width: 600px) {
  .product_inner_img {
    width: auto;
    height: auto;
    margin-bottom: 30px;
  }
}
