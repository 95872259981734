.form_wrap {
  border: 0.5px solid #f3f3f3;
  margin: 30px 0;
  border-radius: 5px;
  padding: 30px 30px;
}
.form_div {
  display: flex;
  text-align: left;
  justify-content: space-around;
}
.checkout_product_wrap {
  border: 0.5px solid #f3f3f3;
  margin: 30px 0;
  border-radius: 5px;
  padding: 30px 0px;
}
.checkout_product_item_img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.checkout_product_item_img img {
  width: 100%;
}
.checkout_product_item {
  display: flex;
  align-items: center;
  padding: 0 30px;
  padding-bottom: 30px;
  border-bottom: 0.5px solid #f3f3f3;
}
.checkout_product_item_info {
  flex-grow: 1;
  text-align: left;
  padding: 15px 15px;
  width: calc(100% - 100px);
}

.checkout_product_item_info h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkout_product_item_info h4 a {
  font-size: 16px;
  color: #333333;
  margin-bottom: 5px;
  margin-top: 5px;
  position: relative;
  text-decoration: none;
}
.checkout_product_item_info h4 a:hover {
  color: #e3a129;
}
.checkout_item_price {
  font-size: 16px;
  color: #111111;
  font-weight: 500;
}
.checkout_count {
  color: #888881;
  margin-left: 5px;
}
.order_total {
  display: flex;
  color: #333333;
  font-weight: 500;
  padding: 30px 30px 0 30px;
  align-items: flex-end;
}
.order_total_title {
  font-size: 16px;
  text-transform: capitalize;
  margin-right: 10px;
}
.order_total_price {
  font-size: 20px;
}
.cart_sec_title {
  font-size: 18px;
  font-weight: 500;
  color: #0a0a0a;
  margin-bottom: 25px;
  padding-bottom: 10px;
  position: relative;
  text-align: left;
}
.cart_sec_title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: 2px solid #e3a129;
  width: 30px;
}
.checkout_payment {
  text-align: right;
}
.place_order_button.btn.btn-primary {
  display: inline-block;
  padding: 5px 20px;
  background-color: #e3a129;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
}
.place_order_button.btn.btn-primary:hover {
  background-color: #d8294f;
  color: #fff;
  box-shadow: none;
}
.place_order_button.btn.btn-primary:focus {
  box-shadow: none;
}

@media only screen and (max-width: 600px) {
  .form_div {
    flex-direction: column;
  }
}
