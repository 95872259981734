.home-ad-section {
  margin: 20px 0;
}
.home-ads-items {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.home-ads-items-content {
  text-align: left;
}
.home-ads-items-content img {
  width: 100%;
}

.home-full-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: left;
  padding: 180px 180px;
}
.home-full-content h2 {
  font-size: 44px;
  margin-bottom: 20px;
  line-height: 1.2;
}
.home-full-content span {
  font-size: 14px;
  font-weight: 600;
  padding: 0 0 5px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: "poppins";
}
.hom_shop_button {
  display: inline-block;
  color: #000;
  background-color: transparent;
  padding-right: 0;
  padding-left: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-right: 5px;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
}
.hom_shop_button:hover {
  color: #e3a129;
  background: none;
}
.hom_shop_button::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 2px solid;
}
.hom_shop_button:hover::before {
  -webkit-animation: underline-link 0.8s cubic-bezier(0.58, 0.3, 0.005, 1) 0s 1;
  animation: underline-link 0.8s cubic-bezier(0.58, 0.3, 0.005, 1) 0s 1;
  border-bottom: 2px solid #e3a129;
}
@-webkit-keyframes underline-link {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  50.01% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes underline-link {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  50.01% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.hom_sec {
  padding: 70px 0;
}
.hom_sec_title {
  font-size: 28px;
  color: #0a0a0a;
  text-transform: uppercase;
  text-align: center;
  font-style: normal;
  margin-bottom: 55px;
  position: relative;
  font-weight: 600;
  letter-spacing: 0;
  display: table;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
}
.hom_sec_title::before {
  position: absolute;
  content: "";
  width: 70px;
  border-bottom: 4px solid #e3a129;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.home-prod-item {
  display: flex;
  border: 1px solid #f1f1f1;
  margin: 20px 0;
}
.home-prod-item:hover {
  border-color: #e3a129;
}
.home-prod-img {
  width: 150px;
  height: 150px;
}
.home-prod-img img {
  width: 100%;
}
.home-prod-info {
  flex-grow: 1;
  text-align: left;
  padding: 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 150px);
}
.home_prod_title {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0px;
  margin-bottom: 4px;
  color: #e3a129;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home_prod_title:hover {
  color: #e3a129;
}
.home_prod_price {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  line-height: 100%;
}
.home_prod_button {
  display: inline-block;
  padding: 5px 15px;
  background-color: #e3a129;
  font-size: 16px;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  width: fit-content;
}
.home_prod_button:hover {
  background-color: #d8294f;
  color: #fff;
}
.hom_sec_left_ad_wrap_content img,
.hom_sec_right_ad_wrap_content img {
  width: 100%;
}
.hom_sec_ad_wrap {
  margin-bottom: 20px;
}
.insta_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.insta_wrapper > * {
  width: 20%;
}
.insta_item_img img {
  width: 100%;
}
.insta_item {
  position: relative;
}

.insta_item::after {
  position: absolute;
  top: 0px;
  content: "";
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  -ms-transform: scale(0.3);
  -o-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.insta_item:hover::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.insta_item_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  z-index: 5;
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  opacity: 0;
  visibility: hidden;
}
.insta_item_icon > * {
  font-size: 36px;
  color: #f7f7f7;
  display: block;
  margin: 8px auto;
}
.insta_item:hover .insta_item_icon {
  opacity: 1;
  visibility: visible;
}
.hom_full_ad_img a img {
  width: 100%;
}
.hom_inst_sec {
  padding-top: 50px;
}
.hom_insta_head_icon svg {
  font-size: 60px;
  color: #e3a129;
  margin-bottom: 15px;
}
.home_banner_sec {
  padding-top: 25px;
}
@media only screen and (max-width: 600px) {
  .insta_wrapper > * {
    width: 50%;
  }
  .home-ads-items {
    margin-bottom: 15px;
  }
}
