.shop_head {
  margin: 30px 0;
}
.breadcrumb {
  align-items: center;
  margin-bottom: 30px;
}
.breadcrumb-item a {
  color: #888888;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}
.breadcrumb-item a:hover {
  color: #e3a129;
}
.breadcrumb-item.active {
  color: #222;
  font-weight: 500;
  font-size: 14px;
}
.shop_head h3 {
  font-size: 20px;
  letter-spacing: 0;
  font-weight: 600;
  color: #222;
  margin-bottom: 40px;
  text-transform: uppercase;
  text-align: left;
}
.shop_top_control {
  padding: 20px 20px;
  background-color: #f3f3f3;
}
.shop_top_control_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shop_top_control_edit {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.shop_top_control_edit label {
  margin-bottom: 0;
  margin-right: 15px;
  font-weight: 500;
  color: #888888;
}
.shop_top_control_edit select:focus {
  outline: none;
  box-shadow: none;
  border-color: #ced4da;
}

.shop_top_control_sort {
  margin-right: 40px;
}
.shop_top_control_sort_child,
.shop_top_control_filter_child {
  display: flex;
  align-items: center;
}
.shop_top_control_search_btn.btn.btn-primary {
  background-color: #d8294f;
  color: #fff;
  border-color: #d8294f;
}
.shop_top_control_search_btn.btn.btn-primary:focus {
  box-shadow: none;
}
.shop_top_control_search_btn.btn.btn-primary:hover {
  background-color: #e3a129;
  border-color: #e3a129;
}
.shop_items_wrapper {
  padding: 40px 0;
}
.shop_item_card {
  position: relative;
  border: 1px solid #f1f1f1;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 20px;
}
.shop_item_card:hover {
  border-color: #e3a129;
}
.shop_item_card_img {
  position: relative;
}
.shop_item_card_img img {
  width: 100%;
}
.shop_prod_title {
  text-decoration: none;
  margin-bottom: 4px;
  margin-top: 10px;
  color: #e3a129;
  font-weight: 500;
  display: block;
}
.shop_prod_title h4 {
  font-size: 18px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shop_prod_title:hover {
  color: #e3a129;
}
.shop_prod_price {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.shop_prod_original_price {
  color: #aaa;
  margin-right: 5px;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
  text-decoration: line-through;
}
.shop_prod_offer_price {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 0;
}

.shop_prod_add_to_cart_btn {
  display: inline-block;
  padding: 5px 20px;
  background-color: #e3a129;
  font-size: 16px;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  width: fit-content;
  margin-bottom: 20px;
  cursor: pointer;
}
.shop_prod_add_to_cart_btn:hover {
  background-color: #d8294f;
  color: #fff;
}
.page-item.active .page-link {
  background-color: #e3a129;
  border-color: #e3a129;
}
.page-link:hover {
  background-color: #e3a129;
  border-color: #e3a129;
  color: #fff;
}
.page-link:focus {
  box-shadow: none;
}
.page-link {
  color: #e3a129;
}
.shop_pagination {
  display: flex;
  justify-content: center;
}
ul.pagination li {
  padding: 3px 3px;
  border-left: 1px solid #d8294f;
  width: 25px;
}
ul.pagination li:first-child {
  border-left: none;
}
.pagination {
  border: 1px solid #d8294f;
  border-radius: 3px;
}
ul.pagination li.active {
  background-color: #d8294f;
}
ul.pagination li a {
  text-decoration: none;
  color: #d8294f;
}
ul.pagination li.active a {
  color: #fff;
}
ul.pagination li a svg {
  padding-bottom: 2px;
}
@media only screen and (max-width: 600px) {
  .shop_top_control_wrap {
    flex-direction: column;
  }
  .shop_top_control_edit {
    flex-direction: column;
  }
  .shop_top_control_sort {
    margin-right: 0;
  }
  .shop_top_control_edit li {
    margin-bottom: 10px;
  }
}
