.contact_map iframe {
  width: 100%;
}
.contact_parent {
  margin-bottom: 100px;
  margin-top: -135px;
  position: relative;
}
.contact_form_wrap {
  background-color: #fff;
  padding-top: 55px;
  padding-bottom: 55px;
}
.contact_row {
  box-shadow: 0 0 12px 0 rgba(2, 2, 2, 0.16);
}
.form_div_child {
  width: 35%;
}
.form_div_child_textarea {
  width: 85%;
}
.contact_item_wrapper {
  padding-left: 0;
  list-style: none;
}
.contact_item {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}
.contact_item_wrap_parent {
  background-color: #000;
}

.contact_item_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #000;
  padding: 55px 30px;
}
.contact_item_icon {
  border: 2px solid #e3a129;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact_item_icon svg {
  font-size: 20px;
  color: #e3a129;
}
.contact_item_content {
  color: #dddddd;
  text-transform: capitalize;
  font-size: 14px;
  padding-left: 25px;
  text-align: left;
}
.contact_item_wrap h4 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 55px;
  text-transform: uppercase;
  text-align: left;
}
.contact_form_wrap h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 55px;
  text-transform: uppercase;
  text-align: left;
  padding-left: 55px;
}
.contact_social_wrap {
  display: flex;
  padding-left: 0;
  list-style: none;
  align-items: center;
}
.contact_social_wrap li {
  padding-right: 15px;
}
.contact_social_wrap li svg {
  font-size: 23px;
  color: #555;
}
.contact_social_wrap li svg:hover {
  color: #fff;
}
.contact_form_btn.btn.btn-primary {
  display: inline-block;
  padding: 9px 20px;
  color: #fff;
  border: none;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  background-color: #e3a129;
  line-height: 24px;
  border-radius: 30px;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.contact_form_btn.btn.btn-primary:hover {
  background-color: #d8294f;
  color: #fff;
}
.contact_form_btn.btn.btn-primary:focus {
  box-shadow: none;
}
@media only screen and (max-width: 600px) {
  .form_div_child,
  .form_div_child_textarea {
    width: 90%;
    margin: 0 auto;
  }
  .contact_form_wrap h4 {
    padding-left: 20px;
  }
  .contact_social_wrap {
    justify-content: center;
  }
  .contact_parent {
    margin-bottom: 0;
  }
}
