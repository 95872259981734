.testimonial_item {
  box-shadow: 0px 0px 30px -1px rgb(181 181 181 / 12%);
  border-radius: 10px;
  padding: 20px 20px;
  margin: 15px;
  text-align: left;
}
.testimonial_item span {
  padding: 7px;
  background: #e3a129;
  border-radius: 2px;
  color: #6b071d;
}
p.testimonial_item_content {
  margin-top: 10px;
}
.testimonial_info {
  display: flex;
}
.testimonial_info_img {
  width: 20%;
}
.testimonial_info_details {
  padding-left: 10px;
  padding-top: 10px;
}
